<template>
  <sign-page
    title-text="职业资格证书管理"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :title-menus="[{key: 'add', label: '新增'}]"
    @dataFileUpdate="dataFileUpdate"
    :tableActions="tableActions"
    table-size="large"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :data-file-parm="{pDirPath: '/worker_certificate/'}"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request,
  fileRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit || this.$authFunsProxy.applyEdit || this.$authFunsProxy.applyAdd) {
      this.$store.dispatch('loadWorkerList')
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['qualification_certificate_type'],
          label: '证书类型',
          key: 'xlmc'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '发证机关',
          key: 'issueOrg'
        },
        {
          type: 'input',
          label: '签发人',
          key: 'issueUser'
        },
        {
          type: 'datePicker',
          selectDatas: this.$store.getters.certificateList,
          label: '发证日期',
          key: 'getDate'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.workerList,
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '毕业学校',
          key: 'gradSchool'
        },
        {
          type: 'input',
          label: '专业',
          key: 'pro'
        },
        {
          type: 'input',
          label: '类别',
          key: 'mType'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '证件类型',
          field: 'xlmc',
          sort: true,
          filters: this.$store.getters.selectDatas['qualification_certificate_type'].map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        },
        {
          title: '证书编码',
          field: 'code',
          sort: true
        },
        {
          title: '员工姓名',
          field: 'workerName',
          sort: true
        },
        {
          title: '发证机关',
          field: 'issueOrg',
          sort: true
        },
        {
          title: '签发人',
          field: 'issueUser',
          sort: true
        },
        {
          title: '发证日期',
          field: 'getDate',
          sort: true,
          width: 200,
          dataType: Date,
          filterRange: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-')
          },
          text: (rowData) => {
            return rowData && rowData.getDate ? rowData.getDate.slice(0, 10) : '-'
          }
        },
        {
          title: '身份证号',
          field: 'idNo',
          wdith: 185,
          sort: true
        },
        {
          title: '学历',
          field: 'workerEducation',
          sort: true
        },
        {
          title: '毕业学校',
          field: 'gradSchool',
          sort: true
        },
        {
          title: '专业',
          field: 'pro',
          sort: true
        },
        {
          title: '类别',
          field: 'mType',
          sort: true
        // },
        // {
        //   title: '扫描件状态',
        //   field: 'imgStatusText',
        //   sort: true
        // },
        // {
        //   title: '最近更新扫描件时间',
        //   field: 'imgUploadTime',
        //   sort: true,
        //   dataType: Date
        }]
        return data
      }
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'doctor_qua'}
    },
    async loadData () {
      let parm = {
        type: 'doctor_qua'
      }
      let data = await request.get(parm)
      data.forEach(v => {
        v.typeText = (this.$store.getters.certificateTypeList.find(i => i.key === v.type) || {label: v.type}).label
        v.imgStatusText = v.imgUploadTime ? '已上传' : '未上传'
      })
      return data
    },
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (file.type && file.type.includes('image')) {
          if (this.files.length > 0) {
            let res = await this.$dialog.confirm('该扫描件将被覆盖，是否继续操作?')
            if (!res) {
              return
            }
          }
          this.loading = true
          let parm = new FormData()
          parm.append('file', file)
          parm.append('path', '/worker_certificate/' + this.choose.id + '/')
          await fileRequest.upload(parm)
          this.updateUploadTime()
          let i = 0
          while (i < this.files.length) {
            if (this.files[i].path !== '/worker_certificate/' + this.choose.id + '/' + file.name) {
              await fileRequest.del({'fullPath': this.files[i].path})
            }
            i += 1
          }
          this.$notice.success({
            title: '系统提示',
            desc: '扫描件上传完成。'
          })
          this.loading = false
          this.loadFile()
        } else {
          this.$notice.info({
            title: '系统提示',
            desc: '请选择图片文件。'
          })
        }
      }
    },
    async updateUploadTime () {
      await request.update(this.choose.id, Object.assign({}, this.choose, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      }))
      this.pageVm.loadData()
    },
    async loadFile () {
      let path = '/worker_certificate/' + this.choose.id + '/'
      this.loading = true
      try {
        let data = await fileRequest.get({
          path: path
        })
        this.files = data
        if (this.files.length > 0) {
          const fileData = await fileRequest.download({
            path: this.files[0].path
          })
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        console.log(error)
        this.files = []
        this.src = null
      }
      this.loading = false
    }
  },
  data () {
    return {
      files: [],
      choose: null,
      loading: false,
      src: null,
      modal: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
